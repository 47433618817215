@import 'colors';

/* stylelint-disable value-keyword-case */
@_font-family-fallback: Arial, 'Helvetica Neue', sans-serif;
@_font-family: var(--bloko-font-family-override, @_font-family-fallback);
/* stylelint-enable */

@_text-color: @color-gray-80;
@_text-color-secondary: @color-gray-60;
@_text-color-tertiary: @color-gray-50;

// small
@_font-size-small: 12px;
@_line-height-small: 1.34;

// medium
@_font-size-medium: 14px;
@_line-height-medium: 1.43;

// large
@_font-size-large: 16px;
@_line-height-large: 1.5;

// extraLarge
@_font-size-extra-large: 20px;
@_line-height-extra-large: 1.4;

@body-line-height: @_font-size-medium * @_line-height-medium;

// stylelint-disable declaration-property-value-allowed-list
._text-base() {
    font-family: @_font-family;
}

.bloko-text-strong() {
    font-weight: bold;
}

.bloko-text-italic() {
    font-style: italic;
}

.bloko-text-secondary() {
    color: @_text-color-secondary;
}

.bloko-text-tertiary() {
    color: @_text-color-tertiary;
}

.bloko-text-small() {
    line-height: @_line-height-small;
    font-size: @_font-size-small;
}

.bloko-text-medium() {
    line-height: @_line-height-medium;
    font-size: @_font-size-medium;
}

.bloko-text-large() {
    line-height: @_line-height-large;
    font-size: @_font-size-large;
}

.bloko-text-extra-large() {
    line-height: @_line-height-extra-large;
    font-size: @_font-size-extra-large;
}
