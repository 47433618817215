@import '~bloko/common/styles/colors';
@import '~bloko/common/styles/media/_screen';
@import '~bloko/common/styles/text-base';

.steps-selector {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 40px;
}

.steps-selector-label {
    position: absolute;
    text-align: center;
    left: -24px;
    top: -22px;
    width: 16px;
    cursor: default;
}

.steps-selector-radio {
    margin-right: -8px;
}

.steps-selector-description {
    display: flex;
    justify-content: space-between;
    color: @color-gray-80;
    margin-top: 20px;
}

.steps-selector-description-min,
.steps-selector-description-max {
    width: 50%;

    @media @screen-lt-s {
        .bloko-text-small();

        width: 30%;
    }
}

.steps-selector-description-max {
    text-align: right;
}
