@import '~bloko/common/styles/_layers';
@import '~bloko/common/styles/colors';
@import '~bloko/common/styles/media/_screen';
@import '~bloko/common/styles/text-base';
@import '~bloko/common/styles/rounded-corner-base';

.footer-mobile-banner {
    padding: 35px 25px;
    background: @color-gray-10;
    position: relative;
    z-index: @above-content-almost;
    color: @color-gray-80;
    font-weight: normal;
    display: none;

    @media @screen-gt-xs {
        padding: 35px 0;
        display: block;
    }

    @media @screen-gt-s {
        padding: 60px 0 70px;
    }
}

.footer-mobile-banner-app {
    @media @screen-lt-s {
        display: block;
    }

    @media @screen-gt-s {
        padding: 50px 0 40px;
    }
}

.footer-mobile-banner-header {
    text-align: center;

    @media @screen-gt-xs {
        text-align: left;
    }
}

.footer-mobile-banner-description {
    .bloko-text-medium();

    text-align: center;

    @media @screen-gt-xs {
        text-align: left;
    }

    @media @screen-gt-s {
        .bloko-text-large();
    }
}

.footer-mobile-banner-description-additional {
    @media @screen-lt-m {
        display: inline;
    }
}

.footer-mobile-banner-app-button {
    width: 100%;
    max-width: 290px;
    margin: 20px auto 0;

    @media @screen-gt-xs {
        max-width: 200px;
        margin: 25px 0 0;
    }
}

.footer-mobile-banner-image {
    position: relative;
    min-height: 120px;
}

.footer-mobile-banner-image-phone {
    position: absolute;
    background: url('img/mobile-banner-phone.svg?v=23062021') no-repeat 0 0 / contain;

    @media @screen-gt-xs {
        height: 285px;
        width: 260px;
        bottom: -117px;
        left: -20px;
    }

    @media @screen-gt-s {
        bottom: -92px;
        height: 318px;
        left: -25px;
        width: 290px;
    }
}

.footer-mobile-banner-image-invite {
    position: absolute;
    background: url('img/mobile-banner-invite.svg?v=23062021') no-repeat 0 0 / contain;

    @media @screen-gt-xs {
        height: 103px;
        right: -15px;
        top: 95px;
        width: 145px;
    }

    @media @screen-gt-s {
        height: 121px;
        width: 170px;
        right: -60px;
        top: 100px;
    }
}

.footer-mobile-banner-image-app {
    @media @screen-lt-s {
        min-height: 190px;
        max-width: 390px;
        margin: 0 auto;
    }
}

.footer-mobile-banner-image-app .footer-mobile-banner-image-phone {
    @media @screen-lt-s {
        width: 235px;
        height: 258px;
        left: 50%;
        transform: translateX(-55%);
    }
}

.footer-mobile-banner-image-app .footer-mobile-banner-image-invite {
    @media @screen-lt-s {
        height: 88px;
        width: 124px;
        top: 90px;
        right: -30px;
    }
}

.footer-mobile-banner-qr-code {
    width: 100px;
    height: 100px;
    background: url('img/mobile-banner-qr-code.svg') no-repeat 0 0;
    background-size: 100% 100%;
    border-radius: @rounded-corner-default;
    overflow: hidden;

    @media @screen-gt-s {
        width: 110px;
        height: 110px;
    }
}

.footer-mobile-banner-qr-code-jtb {
    background-image: url('img/mobile-banner-qr-code_jtb.svg');
}
