@import '~bloko/common/styles/colors';

.banner {
    height: 100%;
    max-height: none;
    background-color: @color-special-yellow-branded;
    margin: -12px -15px;
    padding-top: 50px;
}

.content {
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    position: relative;
    text-align: center;

    &::after {
        position: absolute;
        content: '';
        background: url('./banner.png') no-repeat;
        bottom: 0;
        background-size: cover;
        height: 68%;
        width: 75%;
    }
}

.title {
    width: 80%;
}

.buttons {
    background-color: @color-white;
    display: flex;
    flex-direction: column;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 24px 16px 12px;
}
