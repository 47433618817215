@import '~bloko/common/styles/colors';
@import '~bloko/common/styles/_layers';
@import '@hh.ru/magritte-ui/breakpoints';
@import '@hh.ru/magritte-ui/tokens';

.wrapper {
    background: @color-white;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: @floating;
    box-shadow: 0 4px 12px fade(@color-special-absolute-black, 15%);
}

.wrapper-top {
    position: relative;
}

.wrapper-magritte {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: auto;
    justify-content: center;
    padding: 24px;
    box-sizing: content-box;
    background: color-mix(in srgb, @magritte-color-background-constant-secondary 90%, transparent);
    backdrop-filter: blur(16px);
    color: @color-white;
    flex-direction: column;
    align-items: flex-start;
    z-index: @floating;

    .screen-gt-xs({
        align-items: center;
        flex-direction: row;
        padding: 16px;
        bottom: 0;
        top: auto;
    });
}

.content {
    padding: 20px 0;
    display: flex;
    gap: 24px;
    flex-direction: column;

    .screen-gt-xs({
        flex-direction: row;
        align-items: center;
        padding: 14px 0;
    });
}

.text {
    flex: 1;
}

.buttons {
    display: flex;
    gap: 8px;
    flex-direction: column;

    .screen-gt-xs({
        flex-direction: row;
    });
}
