@import '@hh.ru/magritte-ui/tokens';

.floating-button {
    display: flex;
    align-items: center;
    /* stylelint-disable declaration-property-value-allowed-list */
    background-color: @magritte-color-component-button-background-neutral;
    border: 1px solid @magritte-color-component-snackbar-stroke-content;
    /* stylelint-enable declaration-property-value-allowed-list */
    border-radius: @magritte-static-border-radius-600;
    box-sizing: border-box;
    cursor: pointer;
    padding: 12px;
    box-shadow: @magritte-shadow-level-2-x @magritte-shadow-level-2-y @magritte-shadow-level-2-blur
        @magritte-shadow-level-2-spread @magritte-shadow-level-2-color;

    transition-property: transform;
    transition-duration: @magritte-semantic-animation-ease-in-out-200-duration;
    transition-timing-function: @magritte-semantic-animation-ease-in-out-200-timing-function;

    &:active {
        transform: scale(@magritte-semantic-scale-pressed);
        transition-duration: @magritte-semantic-animation-ease-in-out-100-duration;
        transition-timing-function: @magritte-semantic-animation-ease-in-out-100-timing-function;
    }

    &:hover {
        box-shadow: none;
    }
}
