@import '~bloko/common/styles/colors';

.plate {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;

    padding: 16px;
    gap: 16px;
    border-radius: 8px;

    box-sizing: border-box;

    background-color: @color-red-10;
    cursor: pointer;
}

.inline {
    display: inline-flex;
    align-items: center;
    gap: 8px;

    color: @color-blue-60;
    cursor: pointer;
}

.content {
    flex-grow: 1;

    display: flex;
    align-items: center;

    gap: 16px;
}

.text {
    color: @color-gray-80;
}
