@import '../../../styles/mixins/supernova-variables';
@import '@hh.ru/magritte-ui/breakpoints';

.segmented-container {
    width: 100%;

    .screen-gt-s({
        width: 260px;
    });
}

// TODO HH-236416 временный кастом, если этот эксп выиграет, то будем дорабатывать дизайн библиотеку
.segmented-container label > div {
    @media (max-width: @supernova-navi-redesign-small-mobile-width) {
        /* stylelint-disable-next-line declaration-no-important */
        padding-left: 6px !important;
        /* stylelint-disable-next-line declaration-no-important */
        padding-right: 6px !important;
    }
}
