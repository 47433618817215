@import '@hh.ru/magritte-ui/tokens';
@import '@hh.ru/magritte-ui/breakpoints';
@import '~src/styles/mixins/supernova-variables';

.container {
    display: flex;
}

.link-content {
    display: flex;
    line-height: 0;
}

.badge-container {
    display: flex;
    gap: 4px;
    align-items: center;
}

.text-container {
    display: flex;
    align-items: center;
    gap: 8px;
}

.content-container {
    display: inline-block;
}

.text-container-shaded {
    --magritte-ui-text-color-override: @magritte-color-text-contrast;
    --magritte-ui-icon-color-override: @magritte-color-text-contrast;
}

.container:hover .text-container-shaded {
    --magritte-ui-text-color-override: @magritte-color-base-gray-95;
    --magritte-ui-icon-color-override: @magritte-color-base-gray-95;
}

.text-container-light {
    --magritte-ui-text-color-override: @magritte-color-text-primary;
    --magritte-ui-icon-color-override: @magritte-color-text-primary;
}

.container:hover .text-container-light {
    --magritte-ui-text-color-override: @magritte-color-base-graphite-40;
    --magritte-ui-icon-color-override: @magritte-color-base-graphite-40;
}

.text-container-shaded.text-container-redesign {
    --magritte-ui-text-color-override: @magritte-color-base-gray-75;
    --magritte-ui-icon-color-override: @magritte-color-base-gray-75;
}

.container:hover .text-container-shaded.text-container-redesign,
.text-container-active.text-container-redesign.text-container-shaded {
    --magritte-ui-text-color-override: @magritte-color-text-contrast;
    --magritte-ui-icon-color-override: @magritte-color-text-contrast;
}

.text-container-light.text-container-redesign {
    --magritte-ui-text-color-override: @magritte-color-text-secondary;
    --magritte-ui-icon-color-override: @magritte-color-text-secondary;
}

.container:hover .text-container-light.text-container-redesign,
.text-container-active.text-container-redesign.text-container-light {
    --magritte-ui-text-color-override: @magritte-color-text-primary;
    --magritte-ui-icon-color-override: @magritte-color-text-primary;
}

.hide-text-on-mobile .text {
    display: none;

    .screen-gt-s({
        display: block;
    });
}
