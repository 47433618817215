.nav-items-wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.horizontal {
    flex-direction: row;
    gap: 20px;
}
