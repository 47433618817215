@import '~bloko/common/styles/colors';
@import '~bloko/common/styles/media/_screen';

.support-chat-plate {
    padding: 0 8px;
}

.messengers {
    margin-top: 16px;
    margin-left: 10px;

    @media @screen-gt-xs {
        margin-left: 20px;
    }
}
