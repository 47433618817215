@import '~bloko/common/styles/media/_screen';

.area-switcher-footer {
    display: none;

    @media @screen-gt-xs {
        display: block;
        text-align: center;
    }
}
