@import '~bloko/common/styles/_layers';
@import '~src/styles/mixins/print';
@import '@hh.ru/magritte-ui/base-variables';
@import '@hh.ru/magritte-ui/breakpoints';
@import '@hh.ru/magritte-ui/tokens';

@title-max-width: 30px;

.float-chatik-activator {
    --float-chatik-shift: 0;

    .noprint();

    border-radius: @magritte-static-border-radius-600;
    box-shadow: @magritte-shadow-level-2-x @magritte-shadow-level-2-y @magritte-shadow-level-2-blur
        @magritte-shadow-level-2-spread @magritte-shadow-level-2-color;

    position: fixed;
    right: 24px;
    bottom: calc(32px + var(--float-chatik-shift));
    z-index: @floating;

    button {
        box-shadow: none;
    }
}

.title {
    flex: 1 0 auto;
    padding: 0 4px;
    margin-left: 4px;
    // stylelint-disable declaration-property-value-allowed-list
    color: @magritte-color-component-button-text-neutral;
    overflow: hidden;
    width: auto;

    .magritte-typography-subtitle-1-semibold();
}
