.noprint() {
    .l-lite & {
        display: none;
    }

    @media print {
        display: none;

        .xs-friendly & {
            display: none;
        }
    }
}

.only-print() {
    display: none;

    .l-lite & {
        display: block;
    }

    @media print {
        display: block;
    }
}
