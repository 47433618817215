@import '@hh.ru/magritte-ui/breakpoints';
@import '~bloko/common/styles/_abstract-icon';

.footer-social {
    display: flex;
    margin-left: -10px;

    .screen-gt-xs({
        margin-left: auto;
    });
}
