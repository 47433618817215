@import '@hh.ru/magritte-ui/breakpoints';
@import '../../styles/mixins/supernova-variables';

.supernova-wrapper {
    display: flex;
    align-items: flex-start;
    gap: 8px;

    &:not(.supernova-wrapper_inline) {
        flex-wrap: wrap;
    }

    .screen-gt-s({
        flex-wrap: nowrap;
        gap: 12px;
    });
}

.supernova-search {
    flex: 1 1 100%;

    .screen-gt-s({
        flex: 1 1 auto;
    });
}

.supernova-wrapper_inline .supernova-search {
    flex: 1 1 auto;
}

.supernova-search-select {
    flex: 1 0 100%;

    .screen-gt-s({
        flex: 0 0 142px;
    });
}

.submit-button {
    flex: 1 0 212px;

    // Оверрайд темы магритта только для одной кнопки
    :global(.theme-zarplata) & {
        div {
            --magritte-button-background-color: @supernova-button-zp-color;
        }

        div:hover {
            --magritte-button-background-color: @supernova-button-zp-color-hover;
        }

        span {
            /* stylelint-disable-next-line declaration-no-important */
            --magritte-button-label-color: @supernova-dropdown-text-color !important;
        }
    }

    .screen-gt-s({
        flex: 0 0 212px;
        padding-left: 12px;
    });

    .screen-gt-m({
        // Стили нижи нужны только для совместимости с блоко-сеткой. При окончательном отказе от блоко удалить.
        body:global(.magritte-old-layout) & {
            padding-left: 22px;
        }
    });
}

.submit-button-compressed {
    flex: none;

    .screen-gt-s({
        flex: none;
    });
}

.submit-button_employer {
    flex: 1 0 228px;

    .screen-gt-s({
        flex: 0 0 228px;
    });
}
