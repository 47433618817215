@import '@hh.ru/magritte-ui/tokens';

.help-modal-left-buttons {
    background-color: @magritte-color-background-secondary;
    padding: 24px 0;
    display: flex;
    justify-content: center;
    gap: 32px;
}

.help-modal-bottom-sheet-buttons {
    background-color: @magritte-color-background-secondary;
    padding: 32px 0 16px;

    display: flex;
    flex-direction: column;
    gap: 16px;
}
