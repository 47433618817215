// tempexp_PORTFOLIO-38449_file
.notification-header {
    padding: 0 12px;
    max-width: 200px;
}

.notification-text {
    padding: 0 12px;
}

.notification-img {
    position: absolute;
    top: -29px;
    right: 30px;
    width: 73px;
    height: 50px;
    background: center center no-repeat url('./img/hearts.png');
}
