// tempexp_PORTFOLIO-38449_file
@import '~bloko/common/styles/media/_screen';
@import '~bloko/common/styles/_layers';
@import '@hh.ru/magritte-ui/tokens';

@padding-close: 8px;

.temp-notification-manager {
    box-sizing: border-box;
    position: fixed;
    right: 0;
    z-index: @overlay-content;
    width: auto;
    height: 0;
}

.notification-wrapper {
    animation: notify-plate-show 0.6s 0.2s ease-out both;
    margin: 0 16px;

    @media @screen-gt-xs {
        margin: 0 24px 0 0;
    }
}

.notification-inactive {
    animation: notify-plate-hide 0.3s forwards;
}

.notification-content {
    position: relative;
    max-width: 319px;
}

.notification-close {
    position: absolute;
    right: calc(@padding-close - @magritte-static-space-400);
    top: calc(@padding-close - @magritte-static-space-400);
}

@keyframes notify-plate-show {
    0% {
        transform: translateX(150%);
    }

    25% {
        transform: translateX(-50px);
    }

    50% {
        transform: translateX(20px);
    }

    90% {
        transform: translateX(-2px);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes notify-plate-hide {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}
