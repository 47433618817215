@import '@hh.ru/magritte-ui/breakpoints';

@screen-bloko-xs: ~'(max-width: @{breakpoint-bloko-xs-end})';
@screen-magritte-xs: ~'(max-width: @{breakpoint-magritte-xs-end})';

/* stylelint-disable selector-max-compound-selectors */
.screen-xs(@rules, @screen-friendly: none) when (@screen-friendly = 'not-xs-friendly') {
    @media @screen-bloko-xs {
        body:global(.magritte-old-layout):not(:global(.xs-friendly)) & {
            @rules();
        }
    }

    @media @screen-magritte-xs {
        body:not(:global(.magritte-old-layout)):not(:global(.xs-friendly)) & {
            @rules();
        }
    }
}

.screen-xs(@rules, @screen-friendly: none) when (default()) {
    @media @screen-bloko-xs {
        body:global(.magritte-old-layout) & {
            @rules();
        }
    }

    @media @screen-magritte-xs {
        body:not(:global(.magritte-old-layout)) & {
            @rules();
        }
    }
}

.screen-s(@rules, @screen-friendly: none) when (@screen-friendly = 'not-s-friendly') {
    @media @screen-bloko-s {
        body:global(.magritte-old-layout):not(:global(.s-friendly)) & {
            @rules();
        }
    }

    @media @screen-magritte-s {
        body:not(:global(.magritte-old-layout)):not(:global(.s-friendly)) & {
            @rules();
        }
    }
}

.screen-s(@rules, @screen-friendly: none) when (default()) {
    @media @screen-bloko-s {
        body:global(.magritte-old-layout) & {
            @rules();
        }
    }

    @media @screen-magritte-s {
        body:not(:global(.magritte-old-layout)) & {
            @rules();
        }
    }
}

.screen-m(@rules) {
    @media @screen-bloko-m {
        body:global(.magritte-old-layout) & {
            @rules();
        }
    }

    @media @screen-magritte-m {
        body:not(:global(.magritte-old-layout)) & {
            @rules();
        }
    }
}

.screen-l(@rules) {
    @media @screen-bloko-l {
        body:global(.magritte-old-layout) & {
            @rules();
        }
    }

    @media @screen-magritte-gt-m {
        body:not(:global(.magritte-old-layout)) & {
            @rules();
        }
    }
}
/* stylelint-enable selector-max-compound-selectors */

.hidden {
    display: none;
}

.xs {
    .screen-xs({
        display: flex;
    });

    .screen-xs({
        display: none;
    }, 'not-xs-friendly');
}

.s {
    .screen-s({
        display: flex;
    });

    .screen-s({
        display: none;
    }, 'not-s-friendly');

    .screen-xs({
        display: flex;
    }, 'not-xs-friendly');
}

.m {
    .screen-m({
        display: flex;
    });

    .screen-s({
        display: flex;
    }, 'not-s-friendly');
}

.l {
    .screen-l({
        display: flex;
    });
}
