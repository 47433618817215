@import '@hh.ru/magritte-ui/tokens';

.toggler-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    width: 92px;
    height: 48px;
    border-radius: 12px;
    border: 1px solid @magritte-color-background-tertiary;
    background-color: @magritte-color-background-secondary;
    box-sizing: border-box;
}

.toggler-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 8px;

    &:not(.toggler-button_active) {
        path {
            stroke: @magritte-color-base-graphite-65;
        }
    }
}

.toggler-button_active {
    background-color: @magritte-color-background-primary;
    box-shadow: 0 4px 12px 0 @magritte-color-base-graphite-90;
}
