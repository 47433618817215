@import '@hh.ru/magritte-ui/tokens';
@import '@hh.ru/magritte-ui/breakpoints';

.support-contacts-list {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .screen-gt-s({
      display: flex;
      flex-direction: row;
      justify-content: center;
    });
}

.support-contact-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    text-align: center;
}

.stretched {
    flex: 1;
    white-space: nowrap;
}
