@import '@hh.ru/magritte-ui/tokens';
@import '@hh.ru/magritte-ui/breakpoints';

.notification {
    padding: 0;
    width: 100%;

    &::after {
        left: 80px;
        background-color: @magritte-color-base-graphite-90;
    }

    &:last-child::after {
        display: none;
    }

    .screen-gt-xs({
        width: auto;

        &::after {
            left: 88px;
        }
    });
}
