@import '~bloko/common/styles/colors';
@import '~bloko/common/styles/_layers';
@import '~bloko/common/styles/media/_screen';
@import '~bloko/common/styles/text-base';

.wrapper {
    background: @color-white;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: @floating;
    box-shadow: 0 4px 12px fade(@color-special-absolute-black, 15%);
}

.content {
    padding: 20px 0;
    display: flex;
    gap: 24px;
    flex-direction: column;

    @media @screen-gt-xs {
        flex-direction: row;
        align-items: center;
        padding: 14px 0;
    }
}

.text {
    flex: 1;
}

.buttons {
    display: flex;
    gap: 8px;
    flex-direction: column;

    @media @screen-gt-xs {
        flex-direction: row;
    }
}

.hint {
    color: @color-gray-50;
    margin-top: 6px;
    line-height: 1.34;
    /* stylelint-disable-next-line declaration-property-value-allowed-list */
    font-size: @_font-size-small;
}
