@import '@hh.ru/magritte-ui/tokens';
@import '~bloko/common/styles/_abstract-control';
@import '~bloko/common/styles/_layers';
@import '~bloko/common/styles/_column-variables';
@import '~bloko/common/styles/colors';
@import '~bloko/common/styles/text-base';
@import '~bloko/common/styles/media/_screen';
@import '~bloko/common/styles/spacing-base';

@row-highlighted-color: @color-gray-10;
@hiring-manager-expire-time-width: 125px;
@hiring-manager-responses-width: 120px;
@hiring-manager-views-width: 110px;
@hiring-manager-in-progress-width: 110px;
@hiring-manager-suitable-resumes-width: 110px;
@hiring-manager-response-button-width: 120px;

.table-flexible {
    position: relative;
}

.table-flexible-row {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    padding-top: 24px;
    padding-bottom: 24px;

    .table-flexible-rows-group & {
        border-bottom: 0;
        padding-left: 0;
        padding-right: 0;
    }

    @media @screen-gt-s {
        padding-top: 0;
        padding-bottom: 0;
        flex-wrap: nowrap;
    }

    @media @screen-gt-s {
        align-items: center;
    }
}

.table-flexible-row-selected {
    background-color: @magritte-color-background-secondary;
}

.table-flexible-rows-group,
.table-flexible-row {
    border-bottom: 1px solid @color-gray-20;
    padding-left: var(--scrollable-container-side-space);
    padding-right: var(--scrollable-container-side-space);

    &:hover {
        @media @screen-gt-s {
            background-color: @row-highlighted-color;
        }

        @media @screen-gt-s {
            .table-flexible-cell_first-item-sticky {
                background-color: @row-highlighted-color;
            }
        }
    }
}

.table-flexible-rows-group_without-highlighting,
.table-flexible-row_without-highlighting {
    &:hover {
        @media @screen-gt-s {
            background-color: initial;
        }

        @media @screen-gt-s {
            .table-flexible-cell_first-item-sticky {
                background-color: @color-white;
            }
        }
    }
}

.table-flexible-row_head {
    color: @color-gray-50;
    border-top: 1px solid @color-gray-20;
    align-items: stretch;

    &:hover {
        background-color: @color-white;
    }
}

.table-flexible-cell,
.table-flexible-cell-head {
    box-sizing: border-box;
    padding: 24px 12px;
    flex-shrink: 0;
    flex-grow: 0;

    &:first-child {
        padding-left: 0;
    }

    @media @screen-lt-m {
        padding: 0;
        margin-bottom: 5px;

        &:empty {
            display: none;
        }
    }
}

.table-flexible-cell_head {
    padding-top: 16px;
    padding-bottom: 16px;
    display: flex;
    align-items: center;
}

.table-flexible-cell_sorting {
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;
    transition-duration: 0.2s;
    transition-timing-function: linear;
    transition-property: background-color, color, border-color;

    &:hover {
        cursor: pointer;
        background-color: @color-gray-10;
        color: @color-gray-80;
    }
}

.table-flexible-sort-icon {
    margin-left: 5px;
    transition: transform 0.2s linear 0s;
}

.table-flexible-sort-icon_down {
    transform: rotateZ(180deg);
}

.table-flexible-cell_sorting-active {
    border-bottom-color: @color-gray-80;
    color: @color-gray-80;
}

.table-flexible-cell_spacer {
    display: none;

    @media @screen-gt-s {
        display: block;
        flex-grow: 1;
    }
}

.table-flexible-cell_first-item-sticky,
.table-flexible-cell_first-item-sticky-head {
    @media @screen-gt-s {
        background-color: @color-white;
        position: sticky;
        left: var(--scrollable-container-side-space);
        border-right: 1px solid transparent;
        display: flex;
        align-self: stretch;
    }
}

:global(.scrollable-container-has-scroll) {
    .table-flexible-cell_first-item-sticky,
    .table-flexible-cell_first-item-sticky-head {
        border-right: 1px solid @color-gray-20;
    }
}

.table-flexible-content {
    display: none;
}

.table-flexible-content_visible-xs {
    @media @screen-lt-s {
        display: initial;
    }
}

.table-flexible-content_visible-s {
    @media @screen-s {
        display: initial;
    }
}

.table-flexible-content_visible-m {
    @media @screen-m {
        display: initial;
    }
}

.table-flexible-content_visible-l {
    @media @screen-gt-m {
        display: initial;
    }
}

.table-flexible-controls {
    display: block;
    position: absolute;
    right: 0;

    @media @screen-gt-s {
        right: unset;
        margin-top: calc(-1 * (@control-height-scale-small - var(--bloko-checkable-control-size)) / 2);
        margin-left: calc(-@control-height-scale-medium * 1.5);
        padding: var(--bloko-checkable-control-vertical-indent) 0;
        visibility: hidden;
    }
}

.table-flexible-controls_vertical-centred {
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    margin-top: unset;
    margin-bottom: unset;
    padding: unset;
}

.table-flexible-controls_visible {
    visibility: visible;
}

// START стили для отдельных ячеек в контексте вакансий
.table-flexible-cell_vacancy-name,
.table-flexible-cell_draft-name {
    width: 95%;
    margin-left: calc(-1 * var(--checkbox-column));

    @media @screen-gt-s {
        margin-left: 0;
        width: 290px;
    }
}

.table-flexible-cell_group-name {
    width: 95%;

    @media @screen-gt-s {
        margin-left: 0;
        width: 290px;
    }
}

.table-flexible-cell_archive-vacancy-name {
    width: 95%;
    margin-left: calc(-1 * var(--checkbox-column));

    @media @screen-gt-s {
        margin-left: 0;
        width: 310px;
    }
}

.table-flexible-cell_upgrade {
    width: 100%;

    @media @screen-gt-s {
        width: 160px;
    }

    @media @screen-lt-m {
        order: 10;
        margin-top: @spacing-base * 3;
    }
}

.table-flexible-cell_area {
    width: 100%;

    @media @screen-gt-s {
        width: 150px;
    }
}

.table-flexible-cell_archive-vacancy-area {
    width: 100%;

    @media @screen-gt-s {
        width: 190px;
    }
}

.table-flexible-cell_archive-vacancy-profrole {
    width: 100%;

    @media @screen-gt-s {
        width: 190px;
    }
}

.table-flexible-cell_views,
.table-flexible-cell_responses,
.table-flexible-cell_resumes-in-progress,
.table-flexible-cell_response-speed {
    width: 100%;

    @media @screen-gt-xs {
        min-width: 40%;
        width: auto;
    }

    @media @screen-gt-s {
        min-width: 0;
        width: 110px;
    }
}

.table-flexible-cell_responses {
    @media @screen-gt-s {
        width: 120px;
    }
}

.table-flexible-cell_responses-stretched {
    @media @screen-gt-s {
        width: 130px;
    }
}

.table-flexible-cell_suitable-resumes,
.table-flexible-cell_calls {
    width: 100%;

    @media @screen-gt-xs {
        min-width: 40%;
        width: auto;
    }

    @media @screen-gt-s {
        min-width: 0;
        width: 100px;
    }
}

.table-flexible-cell_promotion {
    width: 100%;

    @media @screen-gt-s {
        width: 200px;
    }
}

.table-flexible-cell_expire-time {
    width: 100%;

    @media @screen-gt-s {
        width: 150px;
    }
}

.table-flexible-cell_manager {
    width: 100%;

    @media @screen-gt-s {
        width: 160px;
        order: 2;
    }
}

.table-flexible-cell_awaiting-payment {
    width: 100%;

    @media @screen-gt-s {
        width: 500px;
    }
}

.table-flexible-cell_draft-saved {
    width: 100%;

    @media @screen-gt-s {
        width: 230px;
    }
}

.table-flexible-cell_draft-status {
    width: 100%;

    @media @screen-gt-s {
        width: 260px;
    }
}

.table-flexible-cell_unpremoderated-name {
    width: 100%;

    @media @screen-gt-s {
        width: 290px;
    }
}

.table-flexible-cell_unpremoderated-area {
    width: 100%;

    @media @screen-gt-s {
        width: 150px;
    }
}

.table-flexible-cell_unpremoderated-salary {
    width: 100%;

    @media @screen-gt-s {
        width: 180px;
    }
}

.table-flexible-cell_unpremoderated-manager {
    width: 100%;

    @media @screen-gt-s {
        width: 260px;
    }
}

.table-flexible-cell_unpremoderated-date {
    width: 100%;

    @media @screen-gt-s {
        width: 114px;
    }
}

.table-flexible-cell_unpremoderated-actions {
    width: 100%;

    @media @screen-gt-s {
        width: 140px;
    }

    @media @screen-lt-m {
        order: 10;
        margin-top: @spacing-base * 3;
    }
}

.table-flexible-cell_scheduled-publication-time {
    width: 100%;

    @media @screen-gt-s {
        width: 500px;
    }
}

.table-flexible-cell_vr-vacancy-name {
    width: 100%;

    @media @screen-gt-s {
        width: 392px;
    }
}

.table-flexible-cell_vr-responses-count {
    width: 25%;
    min-width: 130px;
    align-self: center;

    @media @screen-gt-s {
        min-width: 112px;
    }
}

.table-flexible-cell_vr-action {
    min-width: 130px;
    justify-content: center;
}

.table-flexible-cell_archive-vacancy-manager {
    width: 100%;

    @media @screen-gt-s {
        width: 280px;
    }
}

.table-flexible-cell_archive-vacancy-responses {
    width: 100%;

    @media @screen-gt-s {
        width: 160px;
    }
}

.table-flexible-cell_archive-vacancy-days-being-public {
    width: 100%;

    @media @screen-gt-s {
        width: 160px;
    }
}

.table-flexible-cell_archive-vacancy-archivation-time {
    width: 100%;

    @media @screen-gt-s {
        width: 150px;
    }
}

.table-flexible-cell_archive-vacancy-creation-time {
    width: 100%;

    @media @screen-gt-s {
        width: 150px;
    }
}

// END стили для отдельных ячеек в контексте вакансий

// START стили для отдельных ячеек в контексте менеджеров
.table-flexible-cell_manager-name {
    width: 100%;

    @media @screen-gt-s {
        width: 275px;
    }
}

.table-flexible-cell_manager-status {
    width: 100%;

    @media @screen-gt-s {
        width: 170px;
    }
}

.table-flexible-cell_manager-views {
    width: 100%;

    @media @screen-gt-s {
        width: 140px;
    }
}

.table-flexible-cell_manager-stats {
    width: 100%;

    @media @screen-gt-s {
        width: 110px;
    }
}

.table-flexible-cell_manager-replied {
    width: 100%;

    @media @screen-gt-s {
        width: 130px;
    }
}

.table-flexible-cell_manager-reply-time {
    width: 100%;

    @media @screen-gt-s {
        width: 160px;
    }
}

.table-flexible-cell_manager-politness {
    width: 100%;

    @media @screen-gt-s {
        width: 148px;
    }
}

.table-flexible-cell_hiring-manager-vacancies {
    width: 100%;

    @media @screen-gt-s {
        width: 160px;
    }
}

// END стили для отдельных ячеек в контексте менеджеров

// START стили для отдельных ячеек в контексте лицевого счета
.table-flexible-cell_history-services-date-from,
.table-flexible-cell_history-services-date-till,
.table-flexible-cell_history-services-count {
    min-width: 104px;
    max-width: 100%;
    display: inline-block;

    @media @screen-gt-s {
        min-width: auto;
        display: block;
    }
}

.table-flexible-cell_history-services-date,
.table-flexible-cell_history-services-date-from,
.table-flexible-cell_history-services-date-till {
    order: 5;
    margin-top: 16px;
    margin-right: 16px;

    @media @screen-gt-s {
        width: 136px;
        align-self: flex-start;
        order: initial;
        margin-top: 0;
        margin-right: 0;
    }
}

.table-flexible-cell_history-services-subtitle {
    width: 100%;

    @media @screen-gt-s {
        width: 700px;
        align-self: flex-start;
    }
}

.table-flexible-cell_history-services-count {
    order: 5;
    margin-top: 16px;

    @media @screen-gt-s {
        margin-top: 0;
        width: 136px;
        align-self: flex-start;
        order: initial;
    }
}

.table-flexible-cell_history-replenishments-date,
.table-flexible-cell_history-replenishments-payment-time {
    width: 100%;

    @media @screen-gt-s {
        width: 130px;
    }
}

.table-flexible-cell_history-replenishments-sum {
    width: 100%;

    @media @screen-gt-s {
        width: 130px;
    }
}

.table-flexible-cell_history-replenishments-status {
    width: 100%;

    @media @screen-gt-s {
        width: 300px;
    }
}

.table-flexible-cell_history-replenishments-invoice {
    width: 100%;

    @media @screen-gt-s {
        width: 150px;
    }
}

.table-flexible-cell_history-replenishments-bill-uid {
    width: 100%;
    word-wrap: break-word;

    @media @screen-gt-s {
        width: 150px;
    }
}

.table-flexible-cell_history-replenishments-agreement-number {
    width: 100%;
    word-wrap: break-word;

    @media @screen-gt-s {
        width: 120px;
    }
}

.table-flexible-cell_history-replenishments-payer-name {
    width: 100%;

    @media @screen-gt-s {
        width: 240px;
    }
}

.table-flexible-cell_history-replenishmentsagreement-type {
    width: 100%;

    @media @screen-gt-s {
        width: 110px;
    }
}

.table-flexible-cell_history-expenditures-date,
.table-flexible-cell_history-expenditures-date-head {
    width: 100%;

    @media @screen-gt-s {
        width: 120px;
        align-self: flex-start;
    }
}

.table-flexible-cell_history-expenditures-sum,
.table-flexible-cell_history-expenditures-sum-head {
    width: 100%;

    @media @screen-gt-s {
        width: 120px;
        align-self: flex-start;
    }
}

.table-flexible-cell_history-expenditures-services,
.table-flexible-cell_history-expenditures-services-head {
    width: 100%;

    @media @screen-gt-s {
        width: 724px;
        align-self: flex-start;
    }
}

// END стили для отдельных ячеек в контексте лицевого счета

// START стили для отдельных ячеек в контексте документов компании
.table-flexible-cell_employer-documents-management-payer {
    width: 100%;

    @media @screen-gt-s {
        width: 324px;
        align-self: flex-start;
    }
}

.table-flexible-cell_employer-documents-management-inn {
    width: 100%;

    @media @screen-gt-s {
        width: 124px;
        align-self: flex-start;
    }
}

.table-flexible-cell_employer-documents-management-operator {
    width: 100%;
    word-wrap: break-word;

    @media @screen-gt-s {
        width: 96px;
        align-self: flex-start;
    }
}

.table-flexible-cell_employer-documents-management-date {
    width: 100%;

    @media @screen-gt-s {
        width: 104px;
        align-self: flex-start;
    }
}

.table-flexible-cell_employer-documents-management-fio {
    width: 100%;
    word-wrap: break-word;

    @media @screen-gt-s {
        width: 124px;
        align-self: flex-start;
    }
}

.table-flexible-cell_employer-documents-management-phone {
    width: 100%;

    @media @screen-gt-s {
        width: 154px;
        align-self: flex-start;
    }
}

.table-flexible-cell_employer-documents-management-mail {
    width: 100%;
    word-wrap: break-word;

    @media @screen-gt-s {
        width: 174px;
        align-self: flex-start;
    }
}

.table-flexible-cell_employer-documents-management-status {
    width: 100%;

    @media @screen-gt-s {
        width: 186px;
        align-self: flex-start;
    }
}

.table-flexible-cell_employer-deeds-name {
    width: 100%;

    @media @screen-gt-s {
        width: 312px;
        align-self: flex-start;
    }
}

.table-flexible-cell_employer-deeds-act-number {
    width: 100%;

    @media @screen-gt-s {
        width: 124px;
        align-self: flex-start;
    }
}

.table-flexible-cell_employer-deeds-date {
    width: 100%;

    @media @screen-gt-s {
        width: 124px;
        align-self: flex-start;
    }
}

.table-flexible-cell_employer-deeds-bills {
    width: 100%;

    @media @screen-gt-s {
        width: 324px;
        align-self: flex-start;
    }
}

.table-flexible-cell_employer-deeds-acc-download {
    width: 100%;

    @media @screen-gt-s {
        width: 182px;
        align-self: flex-start;
    }
}

.table-flexible-cell_employer-financial-documents-service {
    width: 100%;

    @media @screen-gt-s {
        width: 384px;
    }
}

.table-flexible-cell_employer-financial-documents-number,
.table-flexible-cell_employer-financial-documents-date,
.table-flexible-cell_employer-financial-documents-bill,
.table-flexible-cell_employer-financial-documents-sum,
.table-flexible-cell_employer-financial-documents-documents,
.table-flexible-cell_employer-financial-documents-originals {
    margin-top: @magritte-static-space-400;
    width: 50%;
    display: inline-block;

    @media @screen-gt-s {
        width: 130px;
        display: block;
        margin-top: 0;
    }
}

.table-flexible-cell_employer-financial-documents-number {
    padding-right: @magritte-static-space-200;

    @media @screen-gt-s {
        width: 130px;
        padding-right: 0;
        align-self: flex-start;
    }
}

.table-flexible-cell_employer-financial-documents-date {
    padding-left: @magritte-static-space-200;

    @media @screen-gt-s {
        width: 124px;
        padding-left: 0;
        align-self: flex-start;
    }
}

.table-flexible-cell_employer-financial-documents-payer {
    width: 100%;
    margin-top: @magritte-static-space-400;

    @media @screen-gt-s {
        width: 324px;
        margin-top: 0;
        align-self: flex-start;
    }
}

.table-flexible-cell_employer-financial-documents-bill {
    padding-right: @magritte-static-space-200;

    @media @screen-gt-s {
        width: 144px;
        align-self: flex-start;
        padding-right: 0;
    }
}

.table-flexible-cell_employer-financial-documents-sum {
    padding-left: @magritte-static-space-200;

    @media @screen-gt-s {
        width: 144px;
        padding-left: 0;
        align-self: flex-start;
    }
}

.table-flexible-cell_employer-financial-documents-mobile-cell {
    width: 100%;
}

.table-flexible-cell_employer-financial-documents-documents,
.table-flexible-cell_employer-financial-documents-originals {
    @media @screen-gt-s {
        width: 166px;
        align-self: flex-start;
    }
}

.table-flexible-cell_employer-deed-order-name {
    width: 100%;
    margin-left: calc(-1 * var(--checkbox-column));

    @media @screen-gt-s {
        width: 384px;
        margin-left: 0;
        align-self: flex-start;
    }
}

.table-flexible-cell_employer-deed-order-act-number {
    width: 100%;

    @media @screen-gt-s {
        width: 124px;
        align-self: flex-start;
    }
}

.table-flexible-cell_employer-deed-order-date {
    width: 100%;

    @media @screen-gt-s {
        width: 124px;
        align-self: flex-start;
    }
}

.table-flexible-cell_employer-deed-order-status {
    width: 100%;

    @media @screen-gt-s {
        width: 184px;
        align-self: flex-start;
    }
}

.table-flexible-cell_employer-deed-order-price {
    width: 100%;

    @media @screen-gt-s {
        width: 124px;
        align-self: flex-start;
    }
}

.table-flexible-cell_employer-deed-order-bills {
    width: 100%;

    @media @screen-gt-s {
        width: 184px;
        align-self: flex-start;
    }
}

.table-flexible-cell_employer-deed-order-acc-download {
    width: 100%;

    @media @screen-gt-s {
        width: 324px;
        align-self: flex-start;
    }
}

.table-flexible-cell_employer-carts-name {
    width: 100%;

    @media @screen-gt-s {
        width: 524px;
    }
}

.table-flexible-cell_employer-carts-payer {
    width: 100%;

    @media @screen-gt-s {
        align-self: flex-start;
        width: 234px;
    }
}

.table-flexible-cell_employer-carts-date {
    width: 100%;

    @media @screen-gt-s {
        width: 164px;
        align-self: flex-start;
    }
}

.table-flexible-cell_employer-carts-activation {
    width: 100%;

    @media @screen-gt-s {
        width: 124px;
        align-self: flex-start;
    }
}

.table-flexible-cell_employer-carts-sum {
    width: 100%;

    @media @screen-gt-s {
        width: 144px;
        align-self: flex-start;
    }
}

.table-flexible-cell_employer-carts-sum-reserve {
    width: 100%;

    @media @screen-gt-s {
        width: 144px;
        align-self: flex-start;
    }
}

.table-flexible-cell_employer-carts-num {
    width: 100%;

    @media @screen-gt-s {
        width: 144px;
        align-self: flex-start;
    }
}

.table-flexible-cell_employer-carts-status {
    width: 100%;
    order: 10;

    @media @screen-gt-s {
        width: 184px;
        align-self: flex-start;
        order: initial;
    }
}

.table-flexible-cell_employer-carts-status-stretched {
    width: 100%;
    order: 10;

    @media @screen-gt-s {
        width: 234px;
        align-self: flex-start;
        order: initial;
    }
}

// END стили для отдельных ячеек в контексте документов компании

.table-flexible-cell_hiring-manager-vacancy-name {
    width: 100%;

    @media @screen-gt-s {
        flex-flow: column;
        align-items: start;
        justify-content: center;
        margin-left: 0;
        min-width: 290px;
        flex: 1;
    }
}

.table-flexible-cell_hiring-manager-responses {
    width: 100%;

    @media @screen-gt-s {
        width: @hiring-manager-responses-width;
    }
}

.table-flexible-cell_hiring-manager-expire-time {
    width: 100%;

    @media @screen-gt-s {
        width: @hiring-manager-expire-time-width;
    }
}

.table-flexible-cell_hiring-manager-response {
    width: 100%;

    @media @screen-gt-s {
        width: @hiring-manager-response-button-width;
    }
}
