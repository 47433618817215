@import '@hh.ru/magritte-ui/breakpoints';

@total-medium-modal-width: 600px;

// Этот стиль - экспериментальный код, чтобы заголовок и подзаголовок Modal центрировались вне зависимости от блока actions
// исправить после выхода https://jira.hh.ru/browse/PORTFOLIO-31781, https://jira.hh.ru/browse/PORTFOLIO-31150
.title-container {
    .screen-gt-s({
        padding-left: calc((@total-medium-modal-width - 100%) / 2);
    });
}
