@import '../../bloko/common/styles/media/_screen';

.hidden-not-mobile-friendly() {
    body:not(.s-friendly) &,
    body:not(.xs-friendly) & {
        display: none;
    }
}

.hidden-gt-xs() {
    @media @screen-gt-xs {
        .s-friendly & {
            display: none;
        }
    }
}

.hidden-gt-s() {
    @media @screen-gt-s {
        display: none;
    }
}

.hidden-gt-m() {
    @media @screen-gt-m {
        display: none;
    }
}

.hidden-xs() {
    @media @screen-lt-s {
        .xs-friendly & {
            display: none;
        }
    }
}

.hidden-s() {
    @media @screen-s {
        .s-friendly & {
            display: none;
        }
    }
}

.hidden-m() {
    @media @screen-m {
        display: none;
    }
}

.hidden-lt-m() {
    @media @screen-lt-m {
        .s-friendly &,
        .xs-friendly & {
            display: none;
        }
    }
}

.hidden-lt-l() {
    .hidden-lt-m();
    .hidden-m();
}

.noprint() {
    .l-lite & {
        display: none;
    }

    @media print {
        display: none;

        .xs-friendly & {
            display: none;
        }
    }
}

.only-print() {
    display: none;

    .l-lite & {
        display: block;
    }

    @media print {
        display: block;
    }
}
