@import '@hh.ru/magritte-ui/breakpoints';
@import '@hh.ru/magritte-ui/tokens';
@import '~bloko/common/styles/_layers';
@import '~src/styles/mixins/supernova-variables';

.footer-nav {
    background: @magritte-color-background-primary;
    color: @magritte-color-text-secondary;
    padding: 35px 0;
    position: relative;
    border-top: 1px solid @magritte-color-stroke-neutral;
    .magritte-typography-paragraph-2-regular();
}

.bottom-section-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 24px;

    .screen-gt-xs({
        flex-direction: row-reverse;
        align-items: center;
        gap: 0;
    });
}

.bottom-section {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .screen-gt-xs({
        flex-direction: row;
    });
}
