@import '@hh.ru/magritte-ui/breakpoints';

.auth-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 215px;
}
