@import '@hh.ru/magritte-ui/breakpoints';

.notification {
    display: flex;
    padding: 16px;

    .screen-gt-xs({
        padding: 24px;
    });
}

.content {
    flex: 1 1 auto;
    align-self: center;
    margin-left: 16px;
}

.close {
    flex: 0 0 auto;
    align-self: start;
    margin-left: 16px;
}
