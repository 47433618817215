@import '@hh.ru/magritte-ui/tokens';

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.not-found {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.not-found-icon {
    width: 48px;
    height: 48px;
    border-radius: 24px;
    background: @magritte-color-background-secondary;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loader-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

// TODO в спеке этот размер есть, но на вебе еще не выпустили, будет в задаче PORTFOLIO-32634
.loader svg {
    width: 48px;
    height: 48px;
}

.host-text {
    display: flex;
    gap: 4px;
    align-items: center;
}
