@import '@hh.ru/magritte-ui/tokens';

.empty > span::after {
    position: absolute;
    content: '';
    height: 4px;
    width: 4px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    /* stylelint-disable-next-line declaration-property-value-allowed-list */
    background: @magritte-color-component-badge-text-accent;
    border-radius: 50%;
}
