.icon {
    flex: 0 0 auto;
    width: 24px;
    height: 24px;
    background: 50% 50% no-repeat;
}

.christmas-tree {
    background-image: url('./assets/christmas-tree.svg');
}

.setka {
    background-image: url('./assets/setka.svg');
}
