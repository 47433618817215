@import '@hh.ru/magritte-ui/tokens';

.nav-items-wrapper {
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 8px;
    overflow: hidden;
}

.nav-item {
    position: relative;

    &::before {
        content: '';
        position: absolute;
        left: -11px;
        top: 9px;
        width: 2px;
        height: 2px;
        background-color: @magritte-color-text-secondary;
        border-radius: 100%;
    }
}
