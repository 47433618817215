@import '~src/styles/mixins/supernova-variables';
@import '~bloko/common/styles/_layers';
@import '@hh.ru/magritte-ui/tokens';

.mobile-screen-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: auto;
    overscroll-behavior: contain;
    background: @magritte-color-background-overlay;
    backdrop-filter: blur(2px);
    z-index: @overlay;
}

.mobile-screen-overlay :global(.supernova-dropdown-section::after) {
    content: none;
}

.mobile-screen-overlay > div {
    display: block;
}

.mobile-screen-card {
    display: block;
}

.mobile-screen-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: @supernova-navi-height-lvl-2-xs-redesign;
    padding: 0 16px;
}

.badge-container {
    display: flex;
    gap: 4px;
    align-items: center;
}
