.requisites-img {
    margin-right: 15px;
    margin-bottom: 5px;
    height: 30px;
}

.requisites-img-mastercard {
    width: 67px;
}

.requisites-img-visa {
    width: 103px;
}

.requisites-img-belcard {
    width: 27px;
}

.requisites-img-internetparol {
    width: 75px;
}

.requisites-img-mir {
    width: 91px;
}

.requisites-img-mir-accept {
    width: 73px;
}
