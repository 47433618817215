@import '@hh.ru/magritte-ui/breakpoints';

.geo-switcher-text {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    height: 22px;
    text-align: left;
    word-break: break-all;
}

.geo-switcher-text_hidden-on-s {
    display: none;

    .screen-gt-m({
        display: flex;
        display: -webkit-box;
    });
}
