@import '~bloko/common/styles/colors';
@import '~bloko/common/styles/media/_screen';

.survey-item {
    display: block;
}

.delimiter {
    border-bottom: 1px solid @color-gray-20;

    @media @screen-gt-xs {
        display: none;
    }
}
