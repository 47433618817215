.mobile-app-button {
    width: 124px;
    height: 32px;
    background-image: url('img/store-buttons.svg');
    background-repeat: no-repeat;
    background-size: 141px;
    box-sizing: border-box;
}

.mobile-app-button_android {
    background-position: -4px -4px;
}

.mobile-app-button_ios {
    background-position: -4px -49px;
}

.mobile-app-button_huawei {
    background-position: -4px -94px;
}
