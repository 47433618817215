@import '@hh.ru/magritte-ui/breakpoints';
@import '@hh.ru/magritte-ui/tokens';

.mobile-app-buttons {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .screen-gt-xs({
        display: none;
    });
}

.only-buttons {
    .screen-gt-xs({
        display: flex;
        flex-direction: row;
    });
}

.qr-code {
    display: none;
    width: 144px;
    height: 144px;
    border-radius: 12px;
    justify-content: center;
    align-items: center;
    background: @magritte-color-background-secondary;
    position: relative;

    .screen-gt-xs({
        display: flex;
    });
}

.qr {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 110%;
}

.qr-applicant {
    // QR-code link https://hh.ru/mobile?from=footer_qr
    background-image: url('img/hhru_applicant.svg');
}

.qr-employer {
    // QR-code link https://hh.ru/emp/qr_employer_footer
    background-image: url('img/hhru_employer.svg');
}

.qr-applicant_rabotaby {
    // QR-code link https://rabota.by/mobile?from=footer_qr
    background-image: url('img/rabotaby_applicant.svg');
}

.qr-employer_rabotaby {
    // QR-code link https://rabota.by/emp/qr_employer_footer
    background-image: url('img/rabotaby_employer.svg');
}
