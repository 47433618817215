@import '~bloko/common/styles/media/_screen';
@import '~bloko/common/styles/_column-variables';
@import '~bloko/common/styles/_abstract-control';
@import '~bloko/common/styles/_abstract-modal';

.post-change-modal-content {
    width: auto;
    margin-right: -(@scrollbar-max-with - @control-box-shadow-focus-visible-width);

    @media @screen-gt-xs {
        width: @column-width * 8 - @column-padding;
    }
}
