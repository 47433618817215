@import '~bloko/common/styles/colors';
@import '~bloko/common/styles/_layers';
@import '~bloko/common/styles/_column-variables';

@smartbanner-row-padding: 15px;
@smartbanner-zone-size: 57px;

.wrapper {
    display: flex;
    position: relative;
    width: 100%;
    padding: @smartbanner-row-padding @column-padding;
    background: @color-gray-10;
    -webkit-text-size-adjust: none;
    /* stylelint-disable-next-line declaration-property-value-allowed-list */
    z-index: 1;
    box-sizing: border-box;
    font-size: 0;
}

.title {
    color: @color-gray-80;
}

.close {
    color: @color-gray-40;
    width: @smartbanner-zone-size;
    height: @smartbanner-zone-size;
    line-height: @smartbanner-zone-size;
    align-self: center;
    text-align: center;
    flex-shrink: 0;
    padding: 0;
    border: 0;
    background: none;
}

.icon {
    flex-shrink: 0;
    width: @smartbanner-zone-size;
    height: @smartbanner-zone-size;
    background-size: @smartbanner-zone-size;
    background-position: center;
}

.icon_hh-ios-applicant {
    background-image: url('../../styles/images/logos/apps/hh_ios_applicant_app.svg');
}

.icon_hh-android-applicant {
    background-image: url('../../styles/images/logos/apps/hh_android_applicant_app.svg');
}

.icon_hh-huawei-applicant {
    background-image: url('../../styles/images/logos/apps/hh_android_applicant_app.svg');
}

.icon_hh-ios-employer {
    background-image: url('../../styles/images/logos/apps/hh_ios_employer_app.svg');
}

.icon_hh-android-employer {
    background-image: url('../../styles/images/logos/apps/hh_android_employer_app.svg');
}

.icon_hh-huawei-employer {
    background-image: url('../../styles/images/logos/apps/hh_ios_employer_app.svg');
}

.icon_jtb-ios-applicant {
    background-image: url('../../styles/images/logos/apps/jtb_ios_applicant_app.svg');
}

.icon_jtb-android-applicant {
    background-image: url('../../styles/images/logos/apps/jtb_android_applicant_app.svg?v=24062021');
}

.icon_jtb-ios-employer {
    background-image: url('../../styles/images/logos/apps/jtb_ios_employer_app.svg');
}

.icon_jtb-android-employer {
    background-image: url('../../styles/images/logos/apps/jtb_android_employer_app.svg');
}

.icon_zp-ios-applicant {
    background-image: url('../../styles/images/logos/apps/zp_android_applicant_app.svg');
}

.icon_zp-android-applicant {
    background-image: url('../../styles/images/logos/apps/zp_android_applicant_app.svg');
}

.icon_zp-huawei-applicant {
    background-image: url('../../styles/images/logos/apps/zp_android_applicant_app.svg');
}

.content {
    flex-grow: 1;
    align-self: center;
    padding: 0 @column-padding;
    vertical-align: middle;
}
