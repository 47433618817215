@import '@hh.ru/magritte-ui/breakpoints';

.support-actions-layout {
    display: grid;
    gap: 12px;

    .screen-gt-s({
        grid-template-columns: repeat(2, 1fr);
    });
}

.wide-action-cell {
    .screen-gt-s({
        grid-column: span 2;
    });
}
