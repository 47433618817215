@import '@hh.ru/magritte-ui/tokens';
@import '@hh.ru/magritte-ui/breakpoints';

.employer-id-container {
    background-color: @magritte-color-background-secondary;
    padding: 16px 24px;
    border-radius: 12px;
    display: flex;
    justify-content: center;

    .screen-gt-s({
      border-radius: 24px;
  });
}

.employer-number-container {
    display: flex;
    align-items: center;
}
