@import '~bloko/common/styles/colors';
@import '~bloko/common/styles/media/_screen';

.container {
    display: block;
    border: 0;
    padding: 0;
    margin: 0;
    cursor: pointer;
    box-shadow: none;
    font-family: inherit;
    width: 100%;
    text-align: left;
}

.banner {
    background: @color-blue-60;
    width: 100%;
    color: @color-white;
}

.wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 5px;
    box-sizing: border-box;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;
    position: relative;
    /* stylelint-disable-next-line declaration-property-value-allowed-list */
    z-index: 1;
    min-height: 72px;

    @media @screen-gt-xs {
        align-items: center;
    }
}

.close {
    box-sizing: content-box;
    position: relative;
    /* stylelint-disable-next-line declaration-property-value-allowed-list */
    z-index: 2;
    cursor: pointer;
}

.content_desktop {
    display: none;

    @media @screen-gt-xs {
        display: flex;
        align-items: center;
    }
}

.content_mobile {
    display: block;

    @media @screen-gt-xs {
        display: none;
    }
}
