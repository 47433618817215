@import '@hh.ru/magritte-ui/breakpoints';

.wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;

    .screen-gt-s({
        align-items: center;
        justify-content: space-between;
        flex-wrap: nowrap;
    });
}

.spacer {
    flex: 1 0 100%;

    .screen-gt-s({
        flex: 1 1 auto;
    });
}

.search-select {
    flex: 1 0 100%;

    .screen-gt-s({
        flex: 0 0 142px;
    });
}

.submit-button {
    flex: 1 0 116px;

    .screen-gt-s({
        flex: 0 0 116px;
    });
}
