@import '../../common/styles/button-base';

@icon-size-16: 16px;
@icon-size-24: 24px;
@icon-size-32: 32px;
@icon-spacing-modifier: 8;

@icon-padding-link: 9px;

@icon-position-default: 0;
@icon-position-action: 1;
@icon-position-inverted: 2;
@icon-position-primary: 3;
@icon-position-secondary: 4;
@icon-position-tertiary: 5;
@icon-position-unique: 6;
@icon-position-dimmed: 7;
@icon-position-impact: 8;

@icon-opacity: 1;
@icon-opacity-disabled: 0.2;

/**
 * Миксин для кастомных иконок
 * Пример использования
 * .supernova-icon {
 *   background-image: url(my-image.svg);
 *   .icon-custom(16px);
 * }
 *
 *  Генерирует классы:
 *
 * .supernova-icon-dynamic — для оборачивания иконки, чтобы работали hover, focus и т.д.
 *
 * .supernova-icon_initial-default,
 * .supernova-icon_initial-action — для выбора базового состояния иконки
 *
 * .supernova-icon_highlighted-default,
 * .supernova-icon_highlighted-action — для выбора состояния иконки по ховеру, фокусу и т.д.
 */
.icon-custom(@size, @step: @size) {
    @views: default action;

    width: @size;
    height: @size;

    /* stylelint-disable selector-nested-pattern, selector-max-universal */
    &-dynamic {
        display: inline-block;
    }
    .abstract-icon-default-rules();

    &,
    &-dynamic > *:visited & {
        background-position: 0 0;
    }

    &-dynamic > *[disabled] &,
    &-dynamic > *[disabled]:hover & {
        background-position: 0 0;
        opacity: @icon-opacity;
    }

    &-dynamic > *:hover &,
    &-dynamic > *:focus &,
    &-dynamic > *:active & {
        background-position: -@step 0;
    }
    /* stylelint-enable */
}

.abstract-icon-color-init(@color, @value) {
    &_initial-@{color},
    &-dynamic > *:visited &_initial-@{color},
    &-dynamic > *:hover &_highlighted-@{color},
    &-dynamic > *:focus &_highlighted-@{color},
    &-dynamic > *:active &_highlighted-@{color} {
        --bloko-icon-color: @value;
    }

    &-dynamic > *[disabled] &_initial-@{color},
    &-dynamic > *[disabled]:hover &_initial-@{color} {
        --bloko-icon-color: @value;

        opacity: @icon-opacity;
    }
}

.abstract-icon-create-views(@views, @sizes) {
    .abstract-icon-default-rules();

    .abstract-icon-step(@views, @sizes, length(@sizes));
}

.abstract-icon-step(@views, @sizes, @counter) when (@counter > 0) {
    @size: extract(@sizes, @counter);
    .abstract-icon-step(@views, @sizes, @counter - 1);

    .abstract-icon-step-class(@size);

    .abstract-icon-views(@views, length(@views), @size);
}

.abstract-icon-step-class(@size) when (@size = unit(@icon-size-16)) {
}

.abstract-icon-step-class(@size) when (default()) {
    @size-without-px: unit(@size);

    &_@{size-without-px} {
        background-size: cover;
        width: @size;
        height: @size;
    }
}

.abstract-icon-views(@views, @counter, @size) when (@counter > 0) {
    @view: extract(@views, @counter);

    @modifier-initial: ~'_initial-@{view}';
    @modifier-following: ~'_highlighted-@{view}';

    @position-variable-name: ~'icon-position-@{view}';
    @position: @@position-variable-name;

    @step: @size + (@size / @icon-spacing-modifier);
    .abstract-icon-views(@views, (@counter - 1), @size);

    .abstract-icon-view-init(@modifier-initial, @modifier-following, @position, unit(@size), @step);
}

.abstract-icon-view-init(@modifier-initial, @modifier-following, @position, @size, @step) when (default()) {
    &@{modifier-initial}&_@{size},
    &-dynamic > *:visited &@{modifier-initial}&_@{size},
    &-dynamic > *:hover &@{modifier-following}&_@{size},
    &-dynamic > *:focus &@{modifier-following}&_@{size},
    &-dynamic > *:active &@{modifier-following}&_@{size},
    &-dynamic > *[disabled] &@{modifier-initial}&_@{size},
    &-dynamic > *[disabled]:hover &@{modifier-initial}&_@{size} {
        background-position: -(@step * @position) 0;
    }
}

.abstract-icon-view-init(@modifier-initial, @modifier-following, @position, @size, @step)
    when
    (@size = unit(@icon-size-16)) {
    &@{modifier-initial},
    &-dynamic > *:visited &@{modifier-initial},
    &-dynamic > *:hover &@{modifier-following},
    &-dynamic > *:focus &@{modifier-following},
    &-dynamic > *:active &@{modifier-following} {
        background-position: -(@step * @position) 0;
    }

    &-dynamic > *[disabled] &@{modifier-initial},
    &-dynamic > *[disabled]:hover &@{modifier-initial} {
        background-position: -(@step * @position) 0;
        opacity: @icon-opacity;
    }
}

.abstract-icon(@type, @version: '', @specificSize: 16px) {
    &_@{type} {
        width: @specificSize;
        height: @specificSize;
        .abstract-icon-set-background-image(@type, @version);
    }
}

.abstract-icon-spin() {
    animation-name: spin;
    animation-duration: 2s;
    animation-iteration-count: infinite;

    /* формула анимации: http://cubic-bezier.com/#.21,.64,.8,.41 */
    animation-timing-function: cubic-bezier(0.21, 0.64, 0.8, 0.41);
    animation-fill-mode: forwards;
}

@keyframes spin {
    from {
        transform: rotate(60deg);
    }

    to {
        transform: rotate(2220deg);
    }
}

.abstract-icon-set-background-image(@type, @version: '') {
    @root: '../../blocks/icon/';
    @type-icon: ~'@{type}';
    @icon-version: ~'@{version}';

    background-image: url('@{root}@{type-icon}-icon.svg@{icon-version}');
}

.abstract-icon-default-rules() {
    display: inline-block;
    background: 0 0 no-repeat;
    vertical-align: middle;
    border: 0;
    outline: none;
    text-decoration: none;
}
