.group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 0;
}

.content {
    display: flex;
    column-gap: 8px;
    justify-content: space-between;
}

.content_compact {
    justify-content: flex-start;
}

.extra {
    display: flex;
    align-items: center;
}

.extra_small {
    column-gap: 4px;
}

.extra_large {
    column-gap: 2px;
}
