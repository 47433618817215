@import '../../common/styles/colors';
@import '../../common/styles/_abstract-control';
@import '../../common/styles/rounded-corner-base';

@link-color: @color-blue-50;
@link-color-active: @color-red-50;
@link-color-visited: @color-violet-50;
@link-color-dimmed: @color-blue-40;
@notify-link-color: @color-green-60;
@secondary-link-color: @color-gray-50;
@tertiary-link-color: @color-gray-70;
@list-link-color: @color-gray-80;
@on-dark-link-color: @color-white;
@on-dark-link-active: @color-red-20;
@link-box-shadow-focus-visible: 0 0 0 @control-box-shadow-focus-visible-width fade(@color-blue-50, 50%);
@link-clickable-indent: 4px;

.abstract-link() {
    display: inline;
    outline: none;
    border: 0;
    text-decoration: none;
    cursor: pointer;
    padding: @link-clickable-indent 0;
    margin: -@link-clickable-indent 0;
    // stylelint-disable-next-line declaration-property-value-allowed-list
    font-size: inherit;
    background: transparent;
    box-shadow: none;
}

.abstract-link-focus-visible() {
    &:global(.focus-visible) {
        box-shadow: @link-box-shadow-focus-visible;
        border-radius: @rounded-corner-default;
    }
}

.abstract-link-focus-visible-inset() {
    &:global(.focus-visible) {
        box-shadow: inset @link-box-shadow-focus-visible;
        border-radius: @rounded-corner-default;
    }
}

.abstract-link-dashed-underline(@color) {
    background-image: linear-gradient(to right, @color, @color 60%, fade(@color-white, 0) 60%, fade(@color-white, 0));
    background-position: 0 bottom;
    background-size: 5px 1px;
    background-repeat: repeat-x;
}

.abstract-link-dashed-underline-with-opacity(@color) {
    .abstract-link-dashed-underline(fade(@color, 50%));
}

.animated-underline(@color) {
    .abstract-link-dashed-underline(@color);

    animation: 0.2s abstract-link-loading infinite linear;
}

@keyframes abstract-link-loading {
    0% {
        background-position-x: 0;
    }

    100% {
        background-position-x: -5px;
    }
}
