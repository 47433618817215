@import '~bloko/common/styles/_layers';

.webcall-wrapper {
    position: fixed;
    top: 1px;
    left: 1px;
    width: 1px;
    height: 1px;
    z-index: @topmost;
}
